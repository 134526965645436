import React from 'react';
import Image from 'next/image';
;
import APP_IMAGES from '../../constants/appImages';
import BussinessAnimation from '../../lotties/businessGoal/drawkit-grape-animation-4-NO-LOOP.json'
import Animation from '../../components/common/Animation'

const BusinessGoal = () => {

  const desktop = (
    <div className="hidden md:block md:mt-20 xl:mt-28 2xl:mt-32 px-8 xl:px-12">

      <div className="mb-14 xl:mb-1 2xl:mb-4 flex justify-between">
        <h2 className="max-w-[730px] font-bold text-4xl xl:text-5xl leading-[64px] xl:leading-[73px] text-gray-darker">
          Our approach to reach your <br />
          <span className="bg-primary text-white p-2 rounded">
            business goals
          </span>
        </h2>
        <div className='hidden xl:block xl:w-[380px] 2xl:w-[520px] xl:-mt-36 2xl:-mb-48 2xl:-mr-4'>
          <Animation file={BussinessAnimation} />
        </div>
      </div>
      <div className="flex gap-10 flex-wrap xl:gap-6 2xl:gap-8 px-12 xl:px-0">
        <div className="md:max-w-[255px] flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <div>
              <Image src={APP_IMAGES.ideateIcon} alt='idea-icon' />
            </div>
            <div className='ml-4 hidden xl:block'>
              <Image src={APP_IMAGES.lineIndicatorDown} alt='line-indicator' />
            </div>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-1">Ideate</h3>
            <p className="font-normal text-base leading-5">
              Turn your idea from concept to MVP
            </p>
          </div>
        </div>
        <div className="md:max-w-[255px]  flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <div>
              <Image src={APP_IMAGES.designIcon} alt='design icon' />
            </div>
            <div className='ml-4 hidden xl:block'>
              <Image src={APP_IMAGES.lineIndicatorUp} alt='line-indicator-up' />
            </div>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-1">Design</h3>
            <p className="font-normal text-base leading-5">
              Sketch out the product to align the user needs
            </p>
          </div>
        </div>
        <div className="md:max-w-[255px]  flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <div>
              <Image src={APP_IMAGES.developIcon} alt='develop' />
            </div>
            <div className='ml-4 hidden xl:block'>
              <Image src={APP_IMAGES.lineIndicatorDown} alt='down ' />
            </div>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-1 ">Develop</h3>
            <p className="font-normal text-base leading-5">
              Convert the designs into a live application
            </p>
          </div>
        </div>
        <div className="md:max-w-[255px]  flex flex-col">
          <div className="mb-4 ">
            <Image src={APP_IMAGES.deployIcon} alt='deploy-icon' />
          </div>
          <div>
            <h3 className="font-bold text-lg mb-1">Deploy</h3>
            <p className="font-normal text-base leading-5">
              Launching the application to the market
            </p>
          </div>
        </div>
      </div>
    </div >
  );

  const mobile = (
    <section className="w-full px-6 sm:w-[90%] sm:mx-auto mt-12 sm:mt-14 md:hidden">
      <h2 className="font-semibold text-2xl text-gray-darker mb-9">
        Our approach to reach your{' '}
        <span className="sm:block bg-primary w-40 h-8 text-white pl-1 rounded">
          business goals{' '}
        </span>
      </h2>
      <div className="flex gap-3 sm:gap-5 mb-11 sm:mb-14">
        <div className="max-w-[160px] sm:w-60 flex flex-col">
          <div className="mb-4">
            <Image src={APP_IMAGES.ideateIcon} alt='idea' width={60.47} height={60.47} />
          </div>
          <div>
            <h3 className="font-bold text-base leading-7 mb-1">Ideate</h3>
            <p className="font-normal text-sm sm:leading-[26px]">
              Turn your idea from concept to MVP
            </p>
          </div>
        </div>
        <div className="max-w-[160px] sm:w-60 flex flex-col">
          <div className="mb-4">
            <Image src={APP_IMAGES.designIcon} alt='design Icon' width={60.47} height={60.47} />
          </div>
          <div>
            <h3 className="font-bold text-base leading-7 mb-1">Design</h3>
            <p className="font-normal text-sm sm:leading-[26px]">
              Sketch out the product to align the user needs
            </p>
          </div>
        </div>
      </div>
      <div className="flex gap-3 sm:gap-5">
        <div className="max-w-[160px] sm:w-60 flex flex-col">
          <div className="mb-4">
            <Image src={APP_IMAGES.developIcon} alt='develop icon' width={60.47} height={60.47} />
          </div>
          <div>
            <h3 className="font-bold text-base leading-7 mb-1 ">Develop</h3>
            <p className="font-normal text-sm sm:leading-[26px]">
              Convert the designs into a live application
            </p>
          </div>
        </div>
        <div className="max-w-[170px] sm:w-60 flex flex-col">
          <div className="mb-4">
            <Image src={APP_IMAGES.deployIcon} alt='deploy' width={60.47} height={60.47} />
          </div>
          <div>
            <h3 className="font-bold text-base leading-7 mb-1">Deploy</h3>
            <p className="font-normal text-sm sm:leading-[26px]">
              Launching the application to the market
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <section className="sm:container sm:mx-auto">
      {desktop}
      {mobile}
    </section>
  );
};

export default BusinessGoal;
